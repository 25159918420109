/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */

 .container {
    --dot-color: #2955fe;
    --dot-fade-color: rgba(90, 112, 255, 0);
    --dot-size: 15px;
    --dot-border-radius: 100%;
    --dot-fall-distance: 15px;
    --dot-margin-ratio: 1.5;
    margin-left: calc(var(--dot-size) * var(--dot-margin-ratio));
    margin-right: calc(var(--dot-size) * var(--dot-margin-ratio));
    margin-top: var(--dot-fall-distance);
    margin-bottom: var(--dot-fall-distance);
 }

 .dot-falling {
    position: relative;
    left: -9999px;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    background-color: var(--dot-color);
    color: var(--dot-color);
    box-shadow: 9999px 0 0 0 var(--dot-color);
    animation: dotFalling 1s infinite linear;
    animation-delay: .1s;
  }
  
  .dot-falling::before, .dot-falling::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-falling::before {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    background-color: var(--dot-color);
    color: var(--dot-color);
    animation: dotFallingBefore 1s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-falling::after {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    background-color: var(--dot-color);
    color: var(--dot-color);
    animation: dotFallingAfter 1s infinite linear;
    animation-delay: .2s;
  }
  
  @keyframes dotFalling {
    0% {
      box-shadow: 9999px calc(0px - var(--dot-fall-distance)) 0 0 var(--dot-fade-color);
    }
    25%,
    50%,
    75% {
      box-shadow: 9999px 0 0 0 var(--dot-color);
    }
    100% {
      box-shadow: 9999px var(--dot-fall-distance) 0 0 var(--dot-fade-color);
    }
  }
  
  @keyframes dotFallingBefore {
    0% {
      box-shadow: calc(9999px + calc(var(--dot-size) * var(--dot-margin-ratio))) calc(0px - var(--dot-fall-distance)) 0 0 var(--dot-fade-color);
    }
    25%,
    50%,
    75% {
      box-shadow: calc(9999px + calc(var(--dot-size) * var(--dot-margin-ratio))) 0 0 0 var(--dot-color);
    }
    100% {
      box-shadow: calc(9999px + calc(var(--dot-size) * var(--dot-margin-ratio))) var(--dot-fall-distance) 0 0 var(--dot-fade-color);
    }
  }
  
  @keyframes dotFallingAfter {
    0% {
      box-shadow: calc(9999px - calc(var(--dot-size) * var(--dot-margin-ratio))) calc(0px - var(--dot-fall-distance)) 0 0 var(--dot-fade-color);
    }
    25%,
    50%,
    75% {
      box-shadow: calc(9999px - calc(var(--dot-size) * var(--dot-margin-ratio))) 0 0 0 var(--dot-color);
    }
    100% {
      box-shadow: calc(9999px - calc(var(--dot-size) * var(--dot-margin-ratio))) 15px 0 0 var(--dot-fade-color);
    }
  }