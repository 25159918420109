@import "./mediaQueries.module";

@mixin light {
  // Brand Colors
  --color-brand-primary: #333366;
  // Buttons
  --color-bt-primary: var(--color-brand-primary);
  --color-bt-text-primary: rgba(255, 255, 255, 1);
  // Backgrounds
  --color-bg-primary: rgba(255, 255, 255, 1);
  --color-bg-secondary: rgba(244, 244, 246, 1);
  --color-bg-almost-primary: rgba(255, 255, 255, 75%);
  --color-bg-input-primary: var(--color-bg-secondary);

  --color-bg-primary-dark: rgba(0, 0, 0, 1);
  // Text colors
  --color-link-primary: #5d93ff;
  --color-text-primary: rgb(22, 25, 25);
  --color-text-75-primary: rgba(22, 25, 25, 0.75);
  --color-text-almost-primary: rgba(22, 25, 25, 0.5);

  --color-text-primary-on-dark: rgba(250, 250, 250, 1);
  --color-text-almost-primary-on-dark: rgba(250, 250, 250, 0.5);

  // Gradient colors
  --color-text-gradient-from: rgba(60, 72, 86, 0.2);
  --color-text-gradient-to: rgba(60, 72, 86, 0.01);
  // Borders
  --border-color-brand: var(--color-brand-primary);
  --border-color-subtle: rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
  --border-radius-secondary: 8px;

  --border-color-subtle-dark: rgba(255, 255, 255, 0.1);
  // Box Shadows
  --box-shadow-primary: 0px 0px 24px rgba(0, 0, 0, 0.1);
  --box-shadow-navbar: 0px 0px 24px rgba(0, 0, 0, 0.1);
  --box-shadow-progress-bar: 0px -4px 24px rgba(0, 0, 0, 0.3);

  // Content
  --max-content-width: #{$maxContentWidth};
  --max-text-body-width: #{$maxTextBodyWidth};

  --navbar-height: #{$navbarBaseHeight};
  --navbar-padding: #{$navbarContainerPaddingDefault};
  --navbar-height-with-padding: calc(
    var(--navbar-height) + (var(--navbar-padding) * 2)
  );

  @include forMobileMedium-under {
    --navbar-padding: #{$navbarContainerPaddingMobileMedium};
  }
}

@mixin dark {
  // Backgrounds
  --color-bg-primary: rgb(15, 15, 19);
  --color-bg-secondary: rgb(22, 22, 27);
  --color-bg-almost-primary: rgba(15, 15, 19, 75%);

  // Text colors
  --color-text-primary: rgba(250, 250, 250, 1);
  --color-text-almost-primary: rgba(250, 250, 250, 0.5);
  --color-text-primary-on-dark: rgba(250, 250, 250, 1);
  --color-text-almost-primary-on-dark: rgba(250, 250, 250, 0.5);
  --color-text-75-primary: rgba(250, 250, 250, 0.75);

  // Gradient colors
  --color-text-gradient-from: rgba(17, 11, 25, 1);
  --color-text-gradient-to: rgba(17, 11, 25, 0.1);

  // Borders
  --border-color-subtle: rgba(255, 255, 255, 0.1);

  // Box Shadows
  --box-shadow-primary: 0px 0px 24px rgba(150, 150, 150, 0.24);
  --box-shadow-navbar: none;
  --box-shadow-progress-bar: 0px -4px 24px rgba(150, 150, 150, 0.3);
}
.light {
  @include light;
}
.dark {
  @include dark;
}

body {
  @include light;
}

@media (prefers-color-scheme: dark) {
  // body {
  //   @include dark;
  // }
}
