@import "../utilities/variables.scss";

.maxTextBodyWidth {
  max-width: var(--max-text-body-width);
}

.maxContentWidth {
  max-width: var(--max-content-width);
}

.mainHorizontalPadding {
  padding-right: $contentPaddingDefault;
  padding-left: $contentPaddingDefault;
}

.mainHorizontalPaddingCompensation {
  margin-right: -$contentPaddingDefault;
  margin-left: -$contentPaddingDefault;
}

@media only screen and (max-width: $breakpointMobileM) {
  .mainHorizontalPadding {
    padding-right: $contentPaddingMobileMedium;
    padding-left: $contentPaddingMobileMedium;
  }

  .mainHorizontalPaddingCompensation {
    margin-right: -$contentPaddingMobileMedium;
    margin-left: -$contentPaddingMobileMedium;
  }
}
