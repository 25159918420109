@import "../utilities/variables.scss";

// Theme

@mixin prefersDark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}
@mixin prefersLight {
  @media (prefers-color-scheme: light) {
    @content;
  }
}

// SCREEN SIZES

@mixin forMaxContentWidth-under {
  @media only screen and (max-width: #{$maxContentWidth+1}) {
    @content;
  }
}
@mixin forMaxContentWidth-above {
  @media only screen and (min-width: #{$maxContentWidth}) {
    @content;
  }
}
@mixin forMobileSmall-under {
  @media only screen and (max-width: #{$breakpointMobileS+1}) {
    @content;
  }
}
@mixin forMobileSmall-above {
  @media only screen and (min-width: #{$breakpointMobileS+1}) {
    @content;
  }
}
@mixin forMobileMedium-above {
  @media only screen and (min-width: #{$breakpointMobileM}) {
    @content;
  }
}
@mixin forMobileMedium-under {
  @media only screen and (max-width: #{$breakpointMobileM}) {
    @content;
  }
}
@mixin forTabletSmall-under {
  @media only screen and (max-width: #{$breakpointTabletS+1}) {
    @content;
  }
}
@mixin forTabletSmall-above {
  @media only screen and (min-width: #{$breakpointTabletS+1}) {
    @content;
  }
}
@mixin forTabletMedium-above {
  @media only screen and (min-width: #{$breakpointTabletM}) {
    @content;
  }
}
@mixin forTabletMedium-under {
  @media only screen and (max-width: #{$breakpointTabletM}) {
    @content;
  }
}
@mixin forLaptopSmall-under {
  @media only screen and (max-width: #{$breakpointLaptopS+1}) {
    @content;
  }
}
@mixin forLaptopSmall-above {
  @media only screen and (min-width: #{$breakpointLaptopS+1}) {
    @content;
  }
}
@mixin forLaptopMedium-above {
  @media only screen and (min-width: #{$breakpointLaptopM}) {
    @content;
  }
}
@mixin forLaptopMedium-under {
  @media only screen and (max-width: #{$breakpointLaptopM}) {
    @content;
  }
}
