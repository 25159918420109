@import "./variables.scss";
@import "./_mediaQueries.module.scss";
// @import url('https://fonts.googleapis.com/css2?family=Gelasio:wght@400;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,800;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');

html {
  font-size: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  // font-weight: 400;
  line-height: 1.65;
}

.a {
  color: var(--color-link-primary);
  text-decoration: none;
}

.p {
  margin-bottom: 1.15rem;
}

.h1-h5 {
  margin: 2.75rem 0 1.05rem;
  // font-family: "Gelasio", "Roboto", sans-serif;
  font-family: "Roboto", sans-serif;
  // font-weight: 400;
  line-height: 1.15;
}

.h1-h5_roboto {
  margin: 2.75rem 0 1.05rem;
  font-family: "Roboto", sans-serif;
  // font-weight: 400;
  line-height: 1.15;
}

.h1 {
  margin-top: 0;
  font-size: 3.052em;
}

.h2 {
  font-size: 2.441em;
}

.h3 {
  font-size: 1.953em;
}

.h4 {
  font-size: 1.563em;
}

.h5 {
  font-size: 1.25em;
}

.small {
  font-size: 0.8em;
}

@include forMobileMedium-under {
  // html {
  //   font-size: 93.8%;
  // }
  .h1 {
    font-size: 2.488em;
  }

  .h2 {
    font-size: 2.074em;
  }

  .h3 {
    font-size: 1.728em;
  }

  .h4 {
    font-size: 1.44em;
  }

  .h5 {
    font-size: 1.2em;
  }

  .small {
    font-size: 0.833em;
  }
}
