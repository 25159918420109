@import "./utilities/variables.scss";
@import "./utilities/dynamicVariables.scss";
@import "./utilities/_spacing.module.scss";
@import "./utilities/_text.module.scss";

.root {
  * {
    box-sizing: border-box;
  }
}

html {
  overflow-x: hidden;
  scroll-padding-top: $navbarBaseHeight + ($navbarContainerPaddingDefault * 2);
}

body {
  margin: 0;
  padding: 0;
  color: var(--color-text-primary);
  background-color: var(--color-bg-primary);
  scroll-padding-top: var(--navbar-height-with-padding);
}

// TEXT

a {
  @extend .a;
}

p {
  @extend .p;
}

h1,
h2,
h3,
h4,
h5 {
  @extend .h1-h5;
}

h1 {
  @extend .h1;
}

h2 {
  @extend .h2;
}

h3 {
  @extend .h3;
}

h4 {
  @extend .h4;
}

h5 {
  @extend .h5;
}

small {
  @extend .small;
}