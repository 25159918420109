// Navbar
$navbarBlur: 16px;

// Breakpoints
$breakpointMobileS: 320px;
$breakpointMobileM: 568px;
$breakpointTabletS: 768px;
$breakpointTabletM: 1024px;
$breakpointLaptopS: 1366px;
$breakpointLaptopM: 1680px;

// Content
$maxContentWidth: 1200px;
$maxTextBodyWidth: 720px;

$navbarBaseHeight: 50px;
$navbarContainerPaddingDefault: 16px;
$navbarContainerPaddingMobileMedium: 4px;

$contentPaddingDefault: 72px;
$contentPaddingMobileMedium: 20px;

$contentMaxWithPadding: $maxContentWidth - ($contentPaddingDefault * 2);

:export {
  breakpointMobileS: $breakpointMobileS;
  breakpointMobileM: $breakpointMobileM;
  breakpointTabletS: $breakpointTabletS;
  breakpointTabletM: $breakpointTabletM;
  breakpointLaptopS: $breakpointLaptopS;
  breakpointLaptopM: $breakpointLaptopM;
  navbarBaseHeight: $navbarBaseHeight;
  navbarContainerPaddingDefault: $navbarContainerPaddingDefault;
  navbarContainerPaddingMobileMedium: $navbarContainerPaddingMobileMedium;
}
