.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.wrapper {
    width: calc(100% - 48px);
    max-width: 600px;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
}
.description {
    & > h1 {
        font-weight: bold;
        // font-size: 28px;
        color: #2955fe
    }
    margin-bottom: 32px;
}
.codeDetailsContainer{
    padding: 18px;
    background-color: #f5f5f5;
    border-radius: 12px;
    margin-bottom: 16px;

    & > .itemName {
        margin: 0;
        margin-bottom: 16px;
        font-size: 21px;
        color: #2955fe;
    }
}

.successMessage{
    padding: 0px 18px;
    color: #2955fe;
    font-weight: 500;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-bottom: 16px;
    border-left: 8px solid #ececec
}
.errorMessage{
    @extend .successMessage;
    color: #e98b88;
}

.form {

    
    & > fieldset{
        display: flex;
        flex-direction: column;
        border:none;
        padding: 0;
        margin: 0;
    }

}
.label {

    display: flex;
    flex-direction: column;
    margin: 12px 0;
    

}
.labelTitle {
    margin-bottom: 8px;

}
.inputField {
    padding: 18px;
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    border-radius: 12px;
    font-size: 16px;

}
.textField {
    @extend .inputField;
    min-height: 200px;
    max-width: 100%;

}
.submitButton {
    align-self: flex-start;
    padding: 12px 24px;
    border: none;
    background-color: #2955fe;
    color: white;
    font-weight: bold;
    border-radius: 12px;

}